@import 'src/styles/mixin';
@import 'src/styles/color';
@import 'src/styles/typography';
@import 'src/styles/constants';

.container {
  width: 100%;
  align-self: stretch;
  justify-content: center; 
  padding: 20px;
  border-radius: 8px;
  background-color: $orange_rating-100;
  color: $black_main-text;

  @include flex-column;

  gap: 16px;
}

.header {
  @include flex-row;

  align-items: center;
  gap: 12px;
}



